&-intro {
  $app-colors: (
    yoga: #f35349,
    pic: #ff6951,
    doctor: #0d67bf,
    watchup: #40a273,
    thanx: #40a273,
    pepsi: #0d67bf,
    glide: #0d67bf,
    keewi: #f35349
  );

  position: relative;
  padding-top: $sub * 5.25;
  padding-bottom: 0;

  @include query(sm) {
    padding-top: $sub * 1.25;
  }

  .section {
    &-title {
      font-size: 24px;
      position: relative;
      z-index: 10;
      line-height: 1;

      @include query(xs) {
        font-size: 38px;
      }

      @include query(sm) {
        font-size: 48px;
        padding-top: $sub * 7;
      }

      @include query(md) {
        font-size: 72px;
        margin-bottom: $sub * 2;
        padding-top: $sub * 7;
      }

      span {
        display: block;
      }

      .intro {
        @include font-size(22px, 1);
        text-transform: lowercase;
        font-family: $font-regular;
        font-variant: small-caps;
        letter-spacing: 2px;
        color: #fff;

        &::after {
          content: "";
          display: block;
          max-width: 50px;
          margin: $suh/2 auto;
          border-bottom: 1px solid rgba(#fff, .5);
        }

        @include query(xs) {
          @include font-size(26px, 1.5);
        }

        @include query(sm) {
          @include font-size(30px, 1.5);
        }
      }

      .highlight {
        max-width: 960px;
        margin-left: auto;
        margin-right: auto;
        color: $brand-primary;

        @include query(sm) {
          margin-bottom: $suh;
        }
      }

      .small {
        @include center(400px);
        @include font-size(16px, 1);
        text-transform: lowercase;
        font-family: $font-medium;
        font-variant: small-caps;
        letter-spacing: 2px;
        color: #000;
        padding-top: $sub;

        @include query(md) {
          @include font-size(22px, 0.9);
        }
      }
    }
  }

  .btn-primary {
    margin-bottom: 1em;
  }

  .carousel {

    @include query(sm) {
      padding-top: 2.5*$sub;
    }

    a {
      display: block;
    }

    &-inner {
      background-image: url("/images/carousel/bg.png");
      background-position: 0% 100%;
      background-repeat: repeat-x;
      padding-bottom: $sub * 1.5;

      @include query(xs) {
        padding-bottom: 0;
      }
    }

    .img {
      &-outer {
        max-width: 1350px;
      }

      &-inner {
        padding-bottom: 87.88%;

        @include query(667px) {
          padding-bottom: 78.63%;
        }

        @include query(sm) {
          padding-bottom: 67.91%;
        }

        @include query(md) {
          padding-bottom: 62.26%;
        }

        @include query(lg) {
          padding-bottom: 55.35%;
        }
      }
    }

    &-meta {
      display: none;

      .is-app {
        display: none;
      }

      // app-specific visibility and coloring
      @each $app, $color in $app-colors {
        &.#{$app} {
          .is-app-#{$app} {
            display: inline-block;

            &.tail {
              display: none;

              @include query(sm) {
                display: inline-block;
              }
            }
          }
        }

        .is-app-#{$app} {
          &.is-app-color {
            color: $color;

            @include query(sm) {
              background-color: $color;
              color: #fff;
            }
          }
        }
      }

      @include query(sm) {
        @include clearfix;
        display: block;
        position: absolute;
        z-index: 10;
        top: 45%;
        left: 5%;
        margin-top: $sub * -2;
        text-align: left;
        pointer-events: none;

        &-image,
        &-inner {
          float: left;
        }
      }

      @include query(md) {
        left: 10%;
      }

      br {
        display: none;

        @include query(sm) {
          display: inline;
        }
      }

      &-mask {
        overflow: hidden;
      }

      &-text {
        @include query(sm) {
          display: inline-block;
          position: relative;
          color: #fff;
          white-space: nowrap;

          span {
            padding: $suh/2 $sub;
          }
        }
      }

      &-title {
        @include font-size(24px, 1.5);
        margin-bottom: 0;
        font-family: $font-bold;
      }

      &-body {
        @include font-size(14px);
        margin-bottom: 0;
        padding-left: $suh;
        padding-right: $suh;

        @include query(sm) {
          padding-left: 0;
          padding-right: 0;
          background-color: #1e1e1e;
        }
      }
    }

    .item {
      color: #fff;
    }

    &-arrows {
      @include clearfix;
      position: absolute;
      top: 60%;
      right: 0;
      margin-top: $sub * -1.5;

      @include query(xs) {
        top: 55%;
      }

      @include query(sm) {
        top: 45%;
        right: $sub * 2;
      }
    }

    &-arrow {
      $size: $sub * 1.5;

      display: inline-block;
      width: $size;
      height: $size;
      float: left;
      margin-left: 1px;
      background-color: #191919;
      color: #5e5e5e;
      line-height: ($size * 1.25);
      text-align: center;
      transition: all .3s;

      @include query(sm) {
        $size: $sub * 2;
        width: $size;
        height: $size;
        line-height: ($size * 1.25);
      }

      &:hover,
      &:focus {
        background-color: $brand-primary;
        color: #fff;

        @include query(sm) {
          width: $sub * 2.5;
        }
      }

      &-right {
        &:hover,
        &:focus {
          @include query(sm) {
            margin-right: -$suh;
          }
        }
      }
    }
  }

  h3 {
    color: $brand-primary;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }

  .next-section {
    position: absolute;
    z-index: 10;
    bottom: 0;
    left: 50%;
    margin-left: -$arrow-size/2;
    color: #fff;
  }
}
